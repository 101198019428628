<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Music, Video, and Text Resources by Region</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <div class="columns is-multiline is-mobile">
        <div 
          class="column is-full-mobile is-half-tablet is-one-quarter-desktop" 
          v-for="region in resource" 
          :key="region.id"
        >
          <a target="_blank" rel="noopener noreferrer" :href="region.link">
            <div class="resourceBox">
              <p class="is-size-4 description">{{ region.region }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResourceService from "../services/resourceService";

export default {
  name: "Resource",
  data() {
    return {
      resource: [],
    };
  },
  async mounted() {
    try {
      this.resource = await ResourceService.getAll();
    } catch (error) {
      console.error("Failed to load resources:", error);
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  margin-top: 30px;
}

.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
}

.resourceBox {
  background: linear-gradient(135deg, #f3f3f3, #e9e9e9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 20px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  transform: translateY(0);
  border: 1px solid #ddd;
  position: relative;
  overflow: hidden;
}

.resourceBox:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.resourceBox:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
}

.resourceBox:hover:before {
  opacity: 1;
}

.description {
  font-size: 1.15rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis (...) for long text */
}

.resourceBox a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.resourceBox:hover .description {
  color: #ff6347; /* A subtle color change on hover for text */
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .resourceBox {
    padding: 15px;
    margin: 5px;
  }

  .description {
    font-size: 1rem; /* Slightly smaller font on mobile */
  }
}
</style>
